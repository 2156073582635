.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	h2 {
		text-align: center;
	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: 36px;
				color: var(--color-primary);
				height: 54px;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

	.best_price-bl {
		position: absolute;
		top: 50px;
		right: 0;
		width: 200px;
		height: 200px;
		z-index: 1;
		border-radius: 100%;
		overflow: hidden;

		@media(max-width: 1199px) {
			right: 0;
		}

		@media screen and (max-width: 1440px) and (max-height: 800px) {
			top: 60px;
			right: 60px;
		}

		@media screen and (max-width: 1024px) and (max-height: 900px) {
			top: 60px;
			right: 60px;
		}

		@media(max-width: 767px) {
			top: 60px;
			right: 60px;
			width: 140px;
			height: 140px;
		}

		@media(max-width: 480px) {
			top: 30px;
			right: 30px;
			height: 120px;
			width: 120px;
		}

		img {
			width: 100%;
		}
	}
}