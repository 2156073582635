@font-face {
	font-family: 'Matter Regular';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/7009Matter-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/7009Matter-Regular.woff2.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}


@font-face {
	font-family: 'Matter Medium';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('https://r.v-office.com/fonts/2004Matter-Medium.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/2004Matter-Medium.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}