.vermieter-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/vermieter.webp);
	}

	.img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	strong {
		font-weight: 600;
	}

	#lastviewed-units {
		display: none;
	}

	.iframe-bl {
		position: relative;
		overflow: hidden;
		width: 100%;
		padding-top: 56.25%;

		/* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
		/* Then style the iframe to fit in the container div with full height and width */
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	}


}