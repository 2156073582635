.static-v1 {
	margin-top: var(--page-margin-top);


	.inner-banner {
		&.anreise {
			background-image: url(RESOURCE/img/Header-Anreise.webp);
		}

		&.partner {
			background-image: url(RESOURCE/img/Header-Partnerschaften.webp);
		}

		&.lpw2021 {
			background-image: url(RESOURCE/img/banner-lpw2021.webp);
		}

		&.kamin {
			background-image: url(RESOURCE/img/Header-Kamin.webp);
		}

		&.hunde {
			background-image: url(RESOURCE/img/Header-Hund.webp);
		}

		&.strandkorb {
			background-image: url(RESOURCE/img/strandkorb_banner.webp);
		}

		&.vermieter {
			background-image: url(RESOURCE/img/Header-Vermieten.webp);
		}

		&.wellnessurlaub {
			background-image: url(RESOURCE/img/Header-Wellnessurlaub.webp);
		}

		&.gutschein {
			background-image: url(RESOURCE/img/hero_gutscheinbestellung.webp);
		}

		&.familienurlaub {
			background-image: url(RESOURCE/img/Header-Familienurlaub.webp);
		}

		&.bestpreis {
			background-image: url(RESOURCE/img/Header-Bestpreisgarantie.webp);
		}

		&.anreisebanner {
			background-image: url(RESOURCE/img/anreiseoptionen01.webp);
			background-position: 50% 100%;
		}

		&.regionen1 {
			background-image: url(RESOURCE/img/Header-Regionen.webp);
		}

	}

	.facility-bl {
		position: relative;
		display: block;
		margin-bottom: 30px;

		.noimage {
			background-image: url(RESOURCE/img/dymmy-img2.png);
			width: 100%;
			height: 320px;
			background-size: cover;
			background-position: 50%;
			display: block;
		}

		.facility-title {
			padding: 10px;
			color: #fff;
			text-align: center;
			background-color: rgba(31, 83, 146, .67059);
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}

	.partner-bl {
		.partner-logo {
			display: block;
			max-width: 400px;
			margin: 0 auto;
			margin-top: 20px;

			&.home {
				max-width: 153px;
			}

			&.anreisen {
				max-width: 728px;
			}
		}
	}

	/*form css*/
	&.gutschein-best-v1 {
		.max-750 {
			max-width: 750px;
			margin: 0 auto;
		}

		.radio-group {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;
		}

		.radio-item {
			position: relative;
			display: inline-block;
			cursor: pointer;
			border: 2px solid transparent;

			overflow: hidden;
			transition: border-color 0.3s, box-shadow 0.3s;
		}

		.radio-item input[type="radio"] {
			display: none;
		}

		.radio-item .image-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 10px;
			background-color: #f9f9f9;
			border: solid 1px #ccc;
		}

		.radio-item img {
			margin-bottom: 10px;
			transition: transform 0.3s;
		}

		.radio-item span {
			font-size: 1rem;
			color: #333;
		}

		.radio-item input[type="radio"]:checked+.image-container {
			border: 2px solid #007bff;
			box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
		}

		.radio-item input[type="radio"]:checked+.image-container img {
			transform: scale(1.1);
		}

		.radio-item dt {
			position: absolute
		}

		.radio-item input[type="radio"]+.icon-tick {
			display: none;
			background-image: url(RESOURCE/img/icon-checked.svg);
			position: absolute;
			width: 24px;
			height: 24px;
			position: absolute;
			top: 10px;
			left: 10px;
			background-size: cover;
		}

		.radio-item input[type="radio"]:checked+.icon-tick {
			display: block;
		}

		.price-options {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			/* Creates 3 equal-width columns */
			gap: .15rem;

			/* Adds space between the grid items */
			.price-option {
				border: 1px solid var(--color-grey);
				padding: 5px;
				border-radius: 5px;
				cursor: pointer;

				.price {
					font-size: 1rem;
					font-weight: bold;
				}
			}

			input[type="radio"]:checked+.option-content {
				background-color: #ccc;
			}

			input[type="radio"]:checked+.option-content {
				background-color: #ccc;
			}
		}



		.price-option input[type="radio"] {
			display: none;
		}

		.option-content {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px;
			background-color: #f0f0f0;
			border-radius: 5px;

			&.text-center {
				padding: 6px;


				input {
					height: 27px;
					text-align: center;
				}
			}
		}


		.price-option.active {
			border-color: #007bff;
			box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
			background-color: #e9f5ff;
		}

		.price-option.active .price {
			color: #007bff;
		}


	}

	&.regionen-container {
		.region-listing {
			padding: 15px;
			height: 320px;

			&.region-12549 {
				background-image: url(RESOURCE/img/Hoernum.jpg);
				width: 100%;
				height: 320px;
				background-size: cover;
				background-position: 50%;
				display: block;

				.content {
					display: none;
				}
			}

			&.region-12552 {
				background-image: url(RESOURCE/img/Wenningstedt.jpg);
				width: 100%;
				height: 320px;
				background-size: cover;
				background-position: 50%;
				display: block;

				.content {
					display: none;
				}
			}

			&.region-12551 {
				background-image: url(RESOURCE/img/Westerland.jpg);
				width: 100%;
				height: 320px;
				background-size: cover;
				background-position: 50%;
				display: block;

				.content {
					display: none;
				}
			}

			&.region-12554 {
				background-image: url(RESOURCE/img/List.webp);
				width: 100%;
				height: 320px;
				background-size: cover;
				background-position: 50%;
				display: block;

				.content {
					display: none;
				}
			}

			&.region-12553 {
				background-image: url(RESOURCE/img/Morsum.webp);
				width: 100%;
				height: 320px;
				background-size: cover;
				background-position: 50%;
				display: block;

				.content {
					display: none;
				}
			}

			&.region-12557 {
				background-image: url(RESOURCE/img/Rantum.webp);
				width: 100%;
				height: 320px;
				background-size: cover;
				background-position: 50%;
				display: block;

				.content {
					display: none;
				}
			}

			&.region-12550 {
				background-image: url(RESOURCE/img/tinnum.webp);
				width: 100%;
				height: 320px;
				background-size: cover;
				background-position: 50%;
				display: block;

				.content {
					display: none;
				}
			}

			.content {

				h2,
				p {
					display: none;
				}

				p {
					&:nth-child(2) {
						display: block;
					}
				}
			}


		}

		.facility-bl {

			border: 1px solid #e7e7e7;
			border-bottom: 0;
			margin: 0;
			height: 100%;
			color: var(--color-black);

			&:hover {
				text-decoration: none;

			}
		}
	}
}